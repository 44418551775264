import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AutoTextBox, BkButtonSmall, ContactButton } from '../'
import { BkButton } from '../'
import { toast } from 'react-toastify';
import { renderToastifyMsg } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faExclamation, faExclamationCircle, faMicrochip } from '@fortawesome/free-solid-svg-icons'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Spinner } from '../../components'
import { AiApi } from '../../api';

const GreenCheckbox = withStyles({
    root: {
        color: 'rgba(0,0,0,0.5)',
        '&$checked': {
            color: 'rgba(106,157,158,1)',
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export interface AiDialogProps {
    open: boolean;
    text: string;
    handleCancelCb: () => void;
    handleSaveCb: (text: string) => void;
}

const AiDialog = (props: AiDialogProps) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selected, setSelected] = useState(0);
    const [selectedId, setSelectedId] = useState(0);
    const [text, setText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [textGeneratedCheck, setTextGeneratedCheck] = useState(false);
    const toastIdRef = useRef<string | number>('');
    const [aiResponse, setAiResponse] = useState('');

    const handleClose = () => {
        setSelected(0);
        setTextGeneratedCheck(false);
        setAiResponse('');
        props.handleCancelCb();
        
    };

    const handleSave = () => {
        if (selected != 0) {
            if (selected == 1) {
                props.handleSaveCb(text);
            }
            else if (selected == 2) {
                props.handleSaveCb(aiResponse);
            }
            setSelected(0);
            setTextGeneratedCheck(false);
            setAiResponse('');
        }
        else {
            if (!toast.isActive(toastIdRef.current)) {
                toastIdRef.current = toast.error(
                    renderToastifyMsg("Velg en tekst å beholde", faExclamationCircle)
                );
            }
        }
    }
    const generateAiText = () => {
        if (text != '' && text != null && text.length > 50 && textGeneratedCheck == false) {
           setIsLoading(true);
           var data = {
              textToImprove: text
           }

           AiApi.getAiImprovedText(data)
              .then(
                 (response) => {
                    setAiResponse(response.data);
                    setIsLoading(false);
                    setTextGeneratedCheck(true);
                 },
                 (error) => {
                    console.log(error);
                 })
        }  
    }


        useEffect(() => {
            setOpenDialog(props.open);
            setText(props.text);
        }, [props.open]);

        const handleChange = (e) => {
            const { value } = e.target;
            var valueToSet = value;
            if (value == selected) {
                valueToSet = "0";
            }
            setSelected(valueToSet);
        }

        const onChange = (value) => {
            setText(value);
        }
        const onChangeAi = (value) => {
            setAiResponse(value);
        }

        return (
            <>
                <Dialog open={openDialog} fullWidth={true} maxWidth={'lg'} onClose={handleClose} aria-labelledby="form-dialog-title" keepMounted={false}>
                    <DialogTitle id="draggable-dialog-title" className="dialog-header">
                        AI-tekstforbedring
                    </DialogTitle>
                    <DialogContent>
                        <div className='module-row align-items-top'>
                            <div className='container'>
                                <div className='module-row ms-1 mb-2'>
                                    <div className='me-2'>Original tekst</div>
                                    <div className='me-2'><GreenCheckbox value="1" className="no-padding" checked={selected == 1} onClick={handleChange} /></div>
                                </div>
                                <div className='row'>
                                    <AutoTextBox onValueChangeCb={onChange} class={selected === 1 ? 'selected' : ''} defaultRows={15} text={text} />
                                </div>
                            </div>

                            <div className='container'>
                                <div className='module-row ms-1 mb-2'>
                                    <div className='me-2'>AI tekst</div>
                                    <div className='me-2'><GreenCheckbox value="2" className="no-padding" checked={selected == 2} onClick={handleChange} /></div>
                                    <BkButtonSmall disabled={textGeneratedCheck} caption='Generer' onClickHandler={generateAiText} />
                                </div>
                                <div className='row'>
                                    <AutoTextBox onValueChangeCb={onChangeAi} class={selected === 2 ? 'selected' : ''} defaultRows={15} text={aiResponse} />
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <ContactButton caption='Avbryt' onClickHandler={handleClose} />
                        <BkButton caption='Legg til' onClickHandler={handleSave} />
                    </DialogActions>
                </Dialog>

                <Spinner isLoading={isLoading} />
            </>
        )
    }
    export default React.memo(AiDialog);