import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faArrowLeft, faDollar } from '@fortawesome/free-solid-svg-icons'
import { BkButton } from '../../components';

const DocumentLinks: React.FC = () => {

   const openPdf = (url) => {
      const win = window.open(url, "_blank");
      win.focus();
   }
   return (
      <div className='container'>
         <div className='row mb-2 bk-row-header'>
            <div className='col-lg-10'> <div className=" bk-row-header-title "> Linker </div></div>
            <div className='col-lg-2'>
               <a href="/documents" className='dashboard-back-btn mx-auto'>
                  <FontAwesomeIcon icon={faArrowLeft} className=" mt-3 me-3" />
                  Hovedmeny </a></div>
         </div>

         <div className="">
            <div className='row mt-3'>
               <div className='col-6'>

                  <div className="dashboard-btn" id="dashboard-link-btn">
                     <FontAwesomeIcon icon={faLink} className="fa-2x" />
                     <a href="https://lovdata.no/dokument/SF/forskrift/2021-06-08-1850" target="_blank" className='dashboard-btn-text'>
                        Forskrift til avhendingslova (tryggere bolighandel) 
                        <br></br> 
                        1. januar 2022
                     </a>
                  </div>

               </div>

               <div className='col-6'>

                  <div className="dashboard-btn" id="dashboard-link-btn">
                     <FontAwesomeIcon icon={faLink} className="fa-2x" />
                     <a href="https://dibk.no/regelverk/forskrift-til-avhendingslova-tryggere-bolighandel/" target="_blank" className='dashboard-btn-text'>
                        Ny forskrift til avhendingslova (tryggere bolighandel)
                        <br></br>
                        1. januar 2022
                     </a>
                  </div>

               </div>
            </div>
            <div className='row mt-3'>
               <div className='col-6'>

                  <div className="dashboard-btn " id="dashboard-link-btn">
                     <FontAwesomeIcon icon={faLink} className="fa-2x" />
                     <a href="https://www.digitalarkivet.no/" target="_blank" className='dashboard-btn-text'>
                        Digitalarkivet</a>
                  </div>

               </div>

               <div className='col-6'>

                  <div className="dashboard-btn " id="dashboard-link-btn">
                     <FontAwesomeIcon icon={faLink} className="fa-2x" />
                     <a href="https://www.kartverket.no/" target="_blank" className='dashboard-btn-text'>
                        Kartverket</a>
                  </div>

               </div>
            </div>
            <div className='row mt-3'>
               <div className='col-6'>
                  <div className="dashboard-btn"  onClick={() => openPdf('/Content/pdf/Rundskriv - Krav til verdsettelse av fast eiendom.pdf')}>
                     <FontAwesomeIcon icon={faDollar}  className="fa-2x flex-05" />
                     <div className='dashboard-btn-text flex-3'>
                        Verdsettelse av fast eiendom. Kan sendes til bank ifm. valideringskode.
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default DocumentLinks;