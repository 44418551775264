import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IApplicationState } from '../../store';
import { actionCreators } from '../../store/auth';
import { useHistory } from 'react-router-dom'
import { RoutesConfig } from '../../config/routes.config';
import Aos from "aos";
import "aos/dist/aos.css";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckSquare, faHouse, faCheck,
    faMoneyCheckAlt, faHouseDamage, faHandHoldingDollar,
    faSearchDollar, faRulerCombined, faCoins, faMoneyBill1Wave, faClipboard, faCalculator, faMedal,
    faMugHot, faHourglass2, faClock, faHammer,
} from '@fortawesome/free-solid-svg-icons'

// import {Paper, Button} from '@material-ui/core'
// import {FiberManualRecord} from '@material-ui/icons'


type HomeProps = typeof actionCreators &
{
    readonly isAuthenticated: boolean;
    readonly role: number;
    readonly userName: string;
};


function Item(props) {
    return (
        <div>
            {props.item.html}
        </div>
    )
}


const Home: React.FC<HomeProps> = ({
    isAuthenticated,
    userName,
    role
}) => {

    useEffect(() => {
        Aos.init({ duration: 1700, once: true })
    }, [])


    const history = useHistory();

    const handleQuestionClick = event => {
        event.preventDefault();
        history.push(RoutesConfig.Contact.path)
    }

    const handleRegistrationClick = event => {
        event.preventDefault();
        history.push(RoutesConfig.Register.path)
    }

    if (isAuthenticated) {
        history.push(RoutesConfig.Projects.path)
    }


    return (
        <div className='container-fluid'>
            <div className='main'>
                <Row className='home'>
                    <div className='col-lg-5' id='home-card'>
                        <Card data-aos="slide-left" className='home-header shadow mt-5 mb-5'>
                            <img alt="header" src="\Content\images\home_page\card_pictures\header_web.svg" height="50%" width="auto" />
                            <Card.Body className='home-card-padding'>
                                <Card.Text className='home-header-content mb-5'>

                                    <div className="mb-4 mt-2 mx-auto" id="company_logo" ><Image fluid alt="Byggekost" src="\Content\images\design\BK-enkel-blue.svg" /> </div>

                                    <p className="mx-auto" id='company_name'>Digitale verktøy for takstbransjen</p>
                                    <p className="mx-5" id='company_subtitle'>Tjenester som brukes av byggmestere og takstingeniører</p>
                                    <div className='mt-5 mb-5'><a href="/register" className='home-header-btn'> Start prøveperiode</a> </div>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Row>

                <Row className='home-function-row' id="function">

                    <div className='col-lg-auto mt-3'><div data-aos="fade-right" data-aos-duration="1500" className='home-row-title mt-3 mb-3' >Noen av våre tjenester:</div></div>

                    <div data-aos="fade-right" className='row home-function-content-row'>
                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faCheckSquare} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text'> Tilstandsrapporter</div>
                        </div>

                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faMoneyCheckAlt} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text'> Verditakster </div>
                        </div>

                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faSearchDollar} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text'> Næringstakster</div>
                        </div>
                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faHandHoldingDollar} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text'> Forhåndstakst</div>
                        </div>
                    </div>

                    <div data-aos="fade-right" className='row home-function-content-row mb-3'>
                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faHammer} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text  ms-auto'> Skadetakst </div>
                        </div>

                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faClipboard} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text ms-auto'> Brevtakst</div>
                        </div>



                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faRulerCombined} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text  ms-auto'> Arealrapport </div>
                        </div>

                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faMoneyBill1Wave} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text  ms-auto'> Festetomt</div>
                        </div>
                    </div>
                    <div data-aos="fade-right" className='row home-function-content-row mb-3'>

                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faHouse} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text ms-auto'> Beregning av nybygg</div>
                        </div>

                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faHouseDamage} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text  ms-auto'> Beregning av fradrag </div>
                        </div>

                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faCalculator} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text  ms-auto'> Kalkulasjoner</div>
                        </div>

                        <div className='col-md-2 col-5 col-centered' id='func_icon'>
                            <FontAwesomeIcon icon={faCoins} className="home-function-icon fa-5x mx-auto d-block" />
                            <div className='home-function-text  ms-auto'> Bo- og bruksrett </div>
                        </div>
                    </div>
                </Row>

                <div id="price">
                    <div className='home-row-title text-center mb-5' id="light" >Dette sier våre kunder:</div>
                    <Row className='mb-5' id='customer_review_web'>

                        <div className='col-lg-3 col-sm-6 col-centered'>



                            <div className=''>
                                <p className='home-customer-text'> Brukervennlig, oversiktlig og tidsbesparende verktøy. <div>Bør prøves.</div></p>
                                <hr></hr>
                                <p className='home-customer-name me-2'>Jim Holm-Johansen</p>
                            </div>



                        </div>
                        <div className='col-lg-3 col-sm-6 col-centered'>



                            <div className=''>
                                <p className='home-customer-text'>Supert arbeidsredskap, innholdsrik og brukervennlig. Anbefales på det sterkeste.</p>
                                <hr></hr>
                                <p className='home-customer-name me-2 ' >Olav Sørensen </p>

                            </div>

                        </div>
                        <div className='col-lg-3 col-sm-6 col-centered '>



                            <div className=''>

                                <p className='home-customer-text'> Utrolig bra verktøy, som virkelig forenkler hverdagen. Så enkelt kan det gjøres. </p>
                                <hr></hr>
                                <p className='home-customer-name me-2 '>Helge Fjellro</p></div>
                        </div>
                        <div className='col-lg-3 col-sm-6 col-centered '>



                            <div className=''>

                                <p className='home-customer-text'> Fint verktøy og tjenester. Akkurat det jeg trenger, <div>til en fornuftig pris.</div></p>
                                <hr></hr>
                                <p className='home-customer-name me-2 '>Roger Johannessen</p></div>
                        </div>





                    </Row>

                    <div className='home-price-container' >

                        <div data-aos="zoom-in" className='home-price-card'>

                            <div >
                                <div className='home-info-text bold' id="home-info-text-title">Årsabonnement</div>
                                <div className='home-info-text' id="home-info-text-lg">5 <span className=''>200,-</span>
                                    <span className='home-info-text ms-3' id="home-small-text">eks.mva</span></div>
                                <hr style={{ color: '#44758C' }} />
                            </div>

                            <div>
                                <div className='home-info-text'>
                                    <FontAwesomeIcon icon={faCheck} className="me-2" /> Tekniske kalkulasjoner</div>
                            </div>

                            <div className='home-info-text bold mb-3' id="home-small-text">
                                <hr style={{ color: '#44758C' }} />
                                Pris er per bruker.
                                <div>Abonnementsperioden starter ved akseptert avtale</div>
                            </div>

                            <div>
                                <a href="/register" className='home-price-button mx-auto d-block'> Registrer deg</a>
                            </div>

                        </div>

                        <div data-aos="zoom-in" className='home-price-card'>

                            <div >
                                <div className='home-info-text bold' id="home-info-text-title">Tillegg - Rapporter</div>
                                <div className='home-info-text mx-auto' id="home-info-text-lg"> 7 <span className=''>500,-</span>
                                    <span className='home-info-text ms-3' id="home-small-text">eks.mva</span></div>
                                <hr style={{ color: '#44758C' }} />
                            </div>
                            <div>
                                <div className='home-info-text'>
                                    <FontAwesomeIcon icon={faCheck} className="me-2" /> Rapporter</div>
                                <div className='home-info-text'>
                                    <FontAwesomeIcon icon={faCheck} className="me-2" /> Andre kalkulasjoner</div>
                            </div>


                            <div className='home-info-text bold mb-3' id="home-small-text">
                                <hr style={{ color: '#44758C' }} />

                                <div>Pris inkluderer 50 stk. rapporter (forhåndsfaktureres)</div>
                                <div>Ingen tidsbegrensning på rapporter / klikk.</div>
                                <div>Tilgang forutsetter betalt årsabonnement.</div>

                            </div>

                            <div>
                                <a href="/register" className='home-price-button mx-auto d-block'> Registrer deg</a>
                            </div>

                        </div>

                    </div>

                </div>

                <Row className='mt-4 mb-4' id='cards_web'>
                    <div className='col-lg-3 col-sm-6'>
                        <div className='home-card-title mb-2'> Alltid oppdatert
                            <div className="mx-5 my-2"><hr className='blue' />
                                <hr className='green' /></div></div>
                        <div className='home-card-content'>Oppdateres jevnlig iht. SSB og<div> prisliste fra Optimera</div></div>

                        <FontAwesomeIcon icon={faClock} className=" fa-10x my-4 mx-auto d-block" id='icon' />

                    </div>

                    <div className='col-lg-3 col-sm-6'>

                        <div className='home-card-title mb-2'> Enkel bruk
                            <div className="mx-5 my-2"><hr className='blue' />
                                <hr className='green' /></div></div>
                        <div className='home-card-content'>Raskt å bruke og sikrer høy kvalitet <div>i de tekniske beregningene</div></div>


                        <FontAwesomeIcon icon={faMugHot} className=" fa-10x my-4 mx-auto d-block" id='icon' />

                    </div>

                    <div className='col-lg-3 col-sm-6 '>

                        <div className='home-card-title mb-2'> Kvalitetssikring
                            <div className="mx-5 my-2"><hr className='blue' />
                                <hr className='green' /></div></div>
                        <div className='home-card-content'>Kvalitetssikret av eksterne kilder. <div>Utregning man kan stole på</div> </div>

                        <FontAwesomeIcon icon={faMedal} className=" fa-10x my-4 mx-auto d-block" id='icon' />

                    </div>

                    <div className='col-lg-3 col-sm-6'>


                        <div className='home-card-title mb-2'> Gratis prøveperiode
                            <div className="mx-5 my-2"><hr className='blue' />
                                <hr className='green' /></div></div>
                        <div className='home-card-content'>Gratis prøveperiode på 14 dager <div>for ny brukere</div></div>

                        <FontAwesomeIcon icon={faHourglass2} className=" fa-10x my-4 mx-auto d-block" id='icon' />


                    </div>
                </Row>

                <Row className='home-about-row' id="about">
                    <div className='col-lg-6'>
                        <Card className="card home-card my-5">
                            <img alt="header" src="\Content\images\home_page\card_pictures\header_web.svg" height="50%" width="auto" />
                            <Card.Body className="home-card-padding">
                                <div className='row mb-3 mx-3'>
                                    <p className='home-info-about-text-main' id='about-text'>Vil du vite mer om personer bak Byggekost?</p></div>



                                <div className='row home-about-content-row mb-4'>
                                    <div className='col-auto col-centered'><a href="/AboutWeb" className='home-price-button'> Les mer om oss</a></div>
                                </div>

                            </Card.Body>
                        </Card>
                    </div>
                </Row>

            </div>
        </div>
    )
}

const mapStateToProps = (state: IApplicationState) => ({
    isAuthenticated: state.auth.isAuthenticated,
    role: state.auth.role,
    userName: state.auth.userName,
});

export default connect(mapStateToProps, actionCreators)(Home);